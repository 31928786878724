import React, {useState} from 'react';
import Modal from '../Modal';
import Button from '../Button';
import styles from './newsletter.module.css';
import Newsletter from './index';

function NewsletterButton() {
  const [showModal, setModal] = useState(false);
  const openModal = () => setModal(true)
  const closeModal = () => setModal(false)

  React.useEffect(() => {
    const shouldOpenNewsletterModal = window.location.search.includes('newsletter');
    if (shouldOpenNewsletterModal) {
      openModal();
    }
  }, []);

  return (
    <>
      {showModal && (
        <Modal onClose={closeModal}>
          <Newsletter />
        </Modal>
      )}
      <Button className={styles.button} onClick={openModal}>
        Subscribe to our newsletter
      </Button>
    </>
  )
}

export default NewsletterButton;
